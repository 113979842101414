import React, { useCallback } from 'react'

import WC from '@moewestudio/with-cookie'
import ReactMarkdown from 'react-markdown'

import useGTM from '@moewestudio/gtm'
import { Typography } from '@material-ui/core'


interface I_WITH_COOKIE_PROPS {
  gdpr: {
    cookie: {
      body?: string,
      accept_btn_label?: string,
      decline_btn_label?: string,
    }
  }
}
const WithCookie: React.FC<I_WITH_COOKIE_PROPS> = ({
  gdpr,
}) => {
  const { init } = useGTM()

  const initGTM = useCallback(() => {
    init({
      id: 'GTM-NCQ2393',
    })
  }, [])

  if (!gdpr || !gdpr.cookie) {
    return null
  }

  const {
    body,
    accept_btn_label,
    decline_btn_label,
  } = gdpr.cookie

  return (
    <WC
      cookieKey='moewe_quiz_with_cookie'
      onLoad={(cookieValue) => {
        cookieValue && initGTM()
      }}
      onAccept={initGTM}
      position='top'
      acceptButtonText={accept_btn_label}
      declineButtonText={decline_btn_label}
      declineButtonProps={{
        variant: 'outlined',
        color: 'primary',
      }}
    >
      <Typography variant='body1'>
        <ReactMarkdown
          disallowedTypes={['paragraph']}
          renderers={{
            link: ({ children, href }) => (
              <a href={href} target='_blank' rel='noreferrer'>
                {children}
              </a>
            ),
          }}
          unwrapDisallowed
        >
          {body}
        </ReactMarkdown>
      </Typography>
    </WC>
  )
}

export default WithCookie