import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useRecoilState } from 'recoil'
import {
  errorsState,
  formState,
  stepState,
} from '../../../state'
import { useRouter } from 'next/router'
import validation from '../validation'
import {
  Grid,
  CircularProgress,
} from '@material-ui/core'
import useTranslate from '../../../hooks/useTranslate'
import axios from 'axios'


const QuizController: React.FC = ({
  children,
}) => {
  const [, setStep] = useRecoilState(stepState)
  const [form] = useRecoilState(formState)
  const [, setErrors] = useRecoilState(errorsState)
  const [isLoading, setIsLoading] = useState(false)

  const router = useRouter()
  const { t } = useTranslate()

  useEffect(() => {
    const { id } = router.query
    if (id && typeof id === 'string') {
      setStep(id)
    }
  }, [])


  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()

    const errors = validation(form, t)
    setErrors(errors)

    if (!Object.keys(errors).length) {
      setIsLoading(true)
      await axios.post(`/api/quiz`, {
        ...form,
        meta: {
          location: location.href.split('?')[0],
        },
      })
        .then(() => {
          router.push('/step/4')
          setIsLoading(false)
        })
        .catch(() => {
          setErrors(p => ({ ...p, submit: 'error' }))
          setIsLoading(false)
        })
    }
  }, [form])


  if (isLoading) {
    return (
      <Grid container justify='center'>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

export default QuizController
