import React from 'react'

import { layoutStyles } from './styles'

import { Container } from '@material-ui/core'

import config from '../../../config'
import useTranslate from '../../../hooks/useTranslate'
import { useRouter } from 'next/router'
import { useResetRecoilState } from 'recoil'
import { errorsState, formState } from '../../../state'


const Layout: React.FC = ({
  children,
}) => {
  const resetForm = useResetRecoilState(formState)
  const resetErrors = useResetRecoilState(errorsState)

  const classes = layoutStyles()
  const { t } = useTranslate()

  const Router = useRouter()

  const handleLogoClick = () => {
    resetForm()
    resetErrors()
    Router.push(config.logoHref)
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='xl'>
        <div className={classes.layout}>
          <div className={classes.header}>
            <Logo onClick={handleLogoClick} />
          </div>
          <div className={classes.children}>
            {children}
          </div>
          <div className={classes.footer}>
            <div className={classes.start}>
              © 2020—{new Date().getFullYear()} <a href='https://moewe.studio/' className={classes.link}>Mowe Studio</a>
            </div>
            <div className={classes.end}>
              <a href='https://moewe.studio/datenschutz' className={classes.link}>{t('data_protection')}</a>
              <a href='https://moewe.studio/impressum' className={classes.link}>{t('imprint')}</a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Layout


const Logo = (props) => (
  <svg width='140' height='34' viewBox='0 0 140 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M30.4997 8.98943C26.6533 8.98943 23.3952 10.5253 21.4494 13.0098C19.7751 10.2994 16.8337 8.98943 13.304 8.98943C10.0007 8.98943 7.19504 10.1639 5.38497 12.3322V9.26046H0V33.3829H5.65648V21.141C5.65648 16.3526 8.19058 13.9133 11.9465 13.9133C15.3856 13.9133 17.3767 15.9912 17.3767 20.2827V33.3829H23.0332V21.141C23.0332 16.3526 25.6125 13.9133 29.3232 13.9133C32.7623 13.9133 34.7534 15.9912 34.7534 20.2827V33.3829H40.4099V19.5599C40.4099 12.2871 36.3372 8.98943 30.4997 8.98943Z' fill='black'/>
    <path d='M54.2849 5.46593C55.8234 5.46593 57 4.33661 57 2.75555C57 1.12932 55.8234 0 54.2849 0C52.7463 0 51.5698 1.12932 51.5698 2.75555C51.5698 4.33661 52.7463 5.46593 54.2849 5.46593ZM63.0637 5.46593C64.6023 5.46593 65.7789 4.33661 65.7789 2.75555C65.7789 1.12932 64.6023 0 63.0637 0C61.5252 0 60.3486 1.12932 60.3486 2.75555C60.3486 4.33661 61.5252 5.46593 63.0637 5.46593Z' fill='black'/>
    <path d='M109.034 9.26046L102.473 27.149L95.9565 9.26046H91.2051L84.5531 27.0586L78.1726 9.26046H72.8328L81.7022 33.3829H87.1324L93.4677 16.7592L99.7124 33.3829H105.143L114.057 9.26046H109.034Z' fill='black'/>
    <path d='M140 21.4572C140 13.9133 134.841 8.98943 127.782 8.98943C120.587 8.98943 115.292 14.1391 115.292 21.3217C115.292 28.5042 120.542 33.6991 128.642 33.6991C132.805 33.6991 136.199 32.3439 138.371 29.769L135.339 26.2907C133.619 28.0524 131.493 28.9107 128.778 28.9107C124.569 28.9107 121.628 26.6521 120.949 23.1286H139.909C139.955 22.5865 140 21.9089 140 21.4572ZM127.782 13.5067C131.493 13.5067 134.163 15.9009 134.615 19.3792H120.904C121.447 15.8557 124.117 13.5067 127.782 13.5067Z' fill='black'/>
    <path d='M58.9179 34.0002C66.4297 34.0002 71.8147 28.8504 71.8147 21.6228C71.8147 14.3951 66.4297 9.29053 58.9179 9.29053C51.4966 9.29053 46.0664 14.3951 46.0664 21.6228C46.0664 28.8504 51.4966 34.0002 58.9179 34.0002ZM58.9179 29.1666C54.8453 29.1666 51.7681 26.2304 51.7681 21.6228C51.7681 17.0151 54.8453 14.0789 58.9179 14.0789C63.0358 14.0789 66.113 17.0151 66.113 21.6228C66.113 26.2304 63.0358 29.1666 58.9179 29.1666Z' fill='#FFD200'/>
  </svg>
)