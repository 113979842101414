
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import useGTM from '@moewestudio/gtm'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'

import QuizController from '../components/quiz/QuizController'
import Layout from '../components/ui/Layout'
import WithCookie from '../components/ui/WithCookie'
import theme from '../theme'
import { NODE_ENV, SITE_DOMAIN } from '../const/env'
import useTranslate from '../hooks/useTranslate'


const CustomApp = ({ Component, pageProps }) => {
  const { UseGTMHookProvider } = useGTM()
  const { pathname } = useRouter()
  const { lang } = useTranslate()

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  if (pathname === '/admin') {
    return (
      <Component {...pageProps} />
    )
  }

  return (
    <RecoilRoot>
      <UseGTMHookProvider>
        <ThemeProvider theme={theme}>
          <WithCookie {...pageProps }/>
          <CssBaseline />
          <Head>
            <meta name='viewport' content='minimum-scale=1, maximum-scale=1, initial-scale=1, width=device-width' />
          </Head>
          <DefaultSeo
            defaultOpenGraphImageHeight={600}
            defaultOpenGraphImageWidth={800}
            dangerouslySetAllPagesToNoFollow={NODE_ENV !== 'production'}
            dangerouslySetAllPagesToNoIndex={NODE_ENV !== 'production'}
            titleTemplate={`%s | MÖWE Studio`}
            openGraph={{
              type: 'website',
              locale: lang,
              url: SITE_DOMAIN,
              site_name: 'Moewe.Studio',
            }}
            twitter={{
              handle: '@handle',
              site: '@site',
              cardType: 'summary_large_image',
            }}
          />
          <Layout>
            <QuizController>
              <Component {...pageProps} />
            </QuizController>
          </Layout>
        </ThemeProvider>
      </UseGTMHookProvider>
    </RecoilRoot>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// CustomApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

const __Page_Next_Translate__ = CustomApp

    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  