const cache = {}

module.exports = {
  locales: ['de', 'en'],
  defaultLocale: 'de',
  pages: {
    '*': ['main'],
  },
  localeDetection: false,

  loadLocaleFrom: async (locale) => {
    if (cache[locale]) {
      return cache[locale]
    }

    const data = await import(`./locales/main/locales.json`).then((res) => res.default)

    const result = data.locales.reduce((acc, item) => {
      acc[item.id] = item[locale]
      return acc
    }, {})

    cache[locale] = result
    return result
  },
}