import { makeStyles } from '@material-ui/core'

export const layoutStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '100%',
  },
  header: {
    padding: '2rem 0',
    '& svg': {
      cursor: 'pointer',
    },
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1.625rem 0 2rem',
  },
  start: {
    fontSize: '1.25rem',
  },
  end: {
    '& $link': {
      marginRight: '2rem',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  children: {
    margin: 'auto 0',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    paddingBottom: 1,
    borderBottom: '1px solid rgba(0,0,0,.12)',
    '&:hover, &:focus': {
      borderBottomColor: 'transparent',
    },
  },
}))