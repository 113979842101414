import { createMuiTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFD200',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 520,
      md: 880,
      lg: 1200,
      xl: 1440,
    },
  },
  /*
    mui-typo!
    h1 | h2 | h3 | h4 | h5 | h6 | subtitle1 | subtitle2 | body1 | body2 | caption | button | overline
  */
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h1: {
      fontSize: '4.5rem',
      lineHeight: 1.2,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '3.25rem',
      lineHeight: 1.2,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '2.75rem',
      lineHeight: 1.2,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '2rem',
      lineHeight: 1.2,
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1.125rem',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '.875rem',
      lineHeight: 1.6,
    },
    caption: {
      fontSize: '.875rem',
      textTransform: 'none',
      color: 'rgba(0,0,0,.4)',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '1rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 100,
        minWidth: 140,
      },
      label: {
        textTransform: 'none',
        fontSize: '1.125rem',
        color: 'black',
        fontWeight: 600,
        lineHeight: 1.36,
      },
      contained: {
        padding: '1.25rem 1.75rem',
        boxShadow: 'none',
        '&:hover, &:focus': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&:hover, &:focus': {
          backgroundColor: '#FFB800',
        },
      },
      outlined: {
        padding: '1.125rem 1.625rem',
      },
      outlinedPrimary: {
        border: '2px solid #FFD200',
        '&:hover, &:focus': {
          backgroundColor: '#FFB800',
          border: '2px solid #FFB800',
        },
      },
    },
  },
})


export default theme